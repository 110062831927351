const surahs = [
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Фатиха Сүрөсү',
    arabic:
      'بِسۡمِ ٱللَّهِ ٱلرَّحۡمَـٰنِ ٱلرَّحِیمِ ٱلۡحَمۡدُ لِلَّهِ رَبِّ ٱلۡعَـٰلَمِینَ  ٱلرَّحۡمَـٰنِ ٱلرَّحِیمِ مَـٰلِكِ یَوۡمِ ٱلدِّینِ إِیَّاكَ نَعۡبُدُ وَإِیَّاكَ نَسۡتَعِینُ  ٱهۡدِنَا ٱلصِّرَ ٰ⁠طَ ٱلۡمُسۡتَقِیمَ  ٱهۡدِنَا ٱلصِّرَ ٰ⁠طَ ٱلۡمُسۡتَقِیمَ ',
    transcription:
      'Бисмилляяхир-Рахмаанир-Рахиим Аль-Хамду Лилляяхи Раббиль-`Алямиин Эр-Рахмаанир-Рахиим Маалики йаумид-диин Ийяякя ня`буду уа ийяякя наста`иин  Ихдиняс-сырааталь-мустакыыйм  Ссырааталь-лязиина ан`амта `алейхим, гайриль-магдууби `алейхим уа ляд-дааааааллиин.',
    quran:
      'Мээримдүү, Ырайымдуу Алланын аты менен! Ааламдардын Роббиси болгон Аллага мактоо! Ал Мээримдүү, Ырайымдуу. Кыямат Күндүн падышасы. Биз Сага гана сыйынып, Сенден гана жардам сурайбыз. Бизди туура жолго баштагын, (ошол) Сенин каарыңа калган жана адашкандардын жолуна эмес, Өзүң тартуулаган жолго (баштагын).',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Аср Сүрөсү',
    arabic:
      'وَٱلۡعَصۡرِ إِنَّ ٱلۡإِنسَـٰنَ لَفِی خُسۡرٍ إِلَّا ٱلَّذِینَ ءَامَنُوا۟ وَعَمِلُوا۟ ٱلصَّـٰلِحَـٰتِ وَتَوَاصَوۡا۟ بِٱلۡحَقِّ وَتَوَاصَوۡا۟ بِٱلصَّبۡرِ ',
    transcription:
      'валь-ʿас̣р  инналь-иннсааналяфиих̮уср иллял-ляз̱иина-аамануу-ва-ʿамилюс̣-с̣аалих̣аати-ва-таваас̣ауу-биль-х̣аккы-ватаваа-с̣ауубис̣-с̣абр',
    quran:
      'Асрга ант! Албетте, (ар бир) инсан зыянда! Бирок, ыйман келтирген, жакшы амалдарды жасагандар жана ак менен сабырдуулукка бири-бирин насаат кылгандар (зыянда эмес).',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Хумазах Сүрөсү',
    arabic:
      'وَیۡل لِّكُلِّ هُمَزَة لُّمَزَةٍ  ٱلَّذِی جَمَعَ مَالا وَعَدَّدَهُۥ یَحۡسَبُ أَنَّ مَالَهُۥۤ أَخۡلَدَهُۥ كَلَّاۖ لَیُنۢبَذَنَّ فِی ٱلۡحُطَمَةِ وَمَاۤ أَدۡرَىٰكَ مَا ٱلۡحُطَمَةُ  نَارُ ٱللَّهِ ٱلۡمُوقَدَةُ ٱلَّتِی تَطَّلِعُ عَلَى ٱلۡأَفۡـِٔدَةِ إِنَّهَا عَلَیۡهِم مُّؤۡصَدَة فِی عَمَد مُّمَدَّدَةِۭ',
    transcription:
      'Уайлюн Ликулли Хумазатил Люмаза. Аль-Лязи Джама`а Малян Уа `Аддада. Йахсабу Анна Маляху Ахляда. Калля Ляйумбазанна Филь-Хутама. Ма Адрака Маль-Хутама. НаруЛлахиль-Мукада. Алляти Таттали`у `Аляль-Аф`ида. Иннаха `Алейхим Му`сада. Фи `Амадин Мумаддада.',
    quran:
      'Адамдарды кылыгы жана тили менен шылдыңдаган бардык шылдыңчыга азап-тозок болсун! Ал мал-мүлктү (адал-арамына карабай) чогултту жана эсептеди. Топтогон мал-дүйнөсү аны түбөлүктүү кылат деп ойлойт. Жок! Алар (Кыяматта) сөзсүз «Хутамага» ташталат! (О, Мухаммад!) Сен «Хутама» эмне экенин билбейсиң.  (Ал) Алланын алоолонгон тозогу  (Денеден тешип өтүп) жүрөктөргө чейин жете турган! Албетте, ал (тозок) алардын үстүнө жабылат. Тартылган устундардын ичинде (болушат).',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Фил Сүрөсү',
    arabic:
      'أَلَمۡ تَرَ كَیۡفَ فَعَلَ رَبُّكَ بِأَصۡحَـٰبِ ٱلۡفِیلِ أَلَمۡ یَجۡعَلۡ كَیۡدَهُمۡ فِی تَضۡلِیل وَأَرۡسَلَ عَلَیۡهِمۡ طَیۡرًا أَبَابِیلَ  تَرۡمِیهِم بِحِجَارَة مِّن سِجِّیل فَجَعَلَهُمۡ كَعَصۡف مَّأۡكُولِۭ',
    quran:
      'Сенин (О, Мухаммад!) Роббиң пил ээлерин эмне кылганын көрбөйсүңбү?  Ал алардын арам ой-максаттарын ойрон кылбадыбы? Ал аларга тобу менен куштарды жөнөтпөдүбү? (Куштар чеңгелиндеги) чопо-таштар менен аларды ташбараңга алышты. (Алла) аларды мойсолгон жалбырактай кылып койду!',
    transcription:
      'алямм-таракяйфа-фаʿаля-раббукя-би-асх̣аабиль-фиииль  алям-йаджʿаль-кяййдаhумм-фии-таад̣лиииль ва-арсаля-ʿалаййhим-т̣айран-абаабиииль тармииhим-бих̣иджааратим-минн-сиджжиииль фаджаʿаляhум-каʿас̣фим-маʾ-куууль',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Курайш Сүрөсү',
    arabic:
      'لِإِیلَـٰفِ قُرَیۡشٍ إِۦلَـٰفِهِمۡ رِحۡلَةَ ٱلشِّتَاۤءِ وَٱلصَّیۡفِ فَلۡیَعۡبُدُوا۟ رَبَّ هَـٰذَا ٱلۡبَیۡتِ  ٱلَّذِیۤ أَطۡعَمَهُم مِّن جُوع وَءَامَنَهُم مِّنۡ خَوۡفِۭ',
    transcription:
      ' ли-ииляяфи-к̣урайййш ииляяфиhим-рих̣латаш-шитааа-ивас̣с̣айййф  фальяʿбудуу-рабба-hааз̱аль-байййт алляз̱иии-ат̣ʿамаhум-минн-джууʿив-ва-ааманаhум-мин-х̣ауууф',
    quran:
      'Курайштын ынтымагы үчүн!  Кыш жана жай мезгилиндеги сапарында алардын ынтымак-тынчтыгы үчүн! Эми, алар (ар кандай таш-кудай, жыгач-кудайларын таштап, өздөрүнө ынтымак жашоо тартуулаган) ушул Үйдүн (Каабанын) Раббисине гана ибадат кылышсын! Ал (Робби) каатчылыкта аларды тамактандырды жана коркунуч(тар)дан аман сактады!',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Маун Сүрөсү',
    arabic:
      'أَرَءَیۡتَ ٱلَّذِی یُكَذِّبُ بِٱلدِّینِ فَذَ ٰ⁠لِكَ ٱلَّذِی یَدُعُّ ٱلۡیَتِیمَ  وَلَا یَحُضُّ عَلَىٰ طَعَامِ ٱلۡمِسۡكِینِ  فَوَیۡل لِّلۡمُصَلِّینَ ٱلَّذِینَ هُمۡ عَن صَلَاتِهِمۡ سَاهُونَ  ٱلَّذِینَ هُمۡ یُرَاۤءُونَ وَیَمۡنَعُونَ ٱلۡمَاعُونَ ',
    transcription:
      'ара-аййтал-ляз̱ии-йукяз̱з̱ибу-бид-дииин  фаз̱ааликял-ляз̱ии-йадуʿʿуль-йятииим ва-ляя-йах̣уд̣д̣у-ʿаляя-т̣аʿаамиль-мискииин фа-ваййлюль-лиль-мус̣аллииин алляз̱иина-hум-ʿанн-с̣аляятиhим-сааhууун алляз̱иина-hум-йурааа-ууун ва-йамнаʿууналь-мааʿууун',
    quran:
      'Сен динди «жалган» деген кишини көрдүңбү?!   Мынакей ал, жетимдин (акысына кыянаттык кылып), түрткүлөгөн адам!  Ал жакырды тамактандырууга (элдерди) үгүттөгөн эмес.  Азап болсун (кээ бир) намаз окуучуларга!  Анткени, алар намаздарын унутуп коюшкан. (Анан да) алар (намаздарын) эл көрсүнгө жасашат. Алар (муктаждардан) буюм-шаймандарды кызганышат.',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Каусар Сүрөсү',
    arabic:
      'إِنَّاۤ أَعۡطَیۡنَـٰكَ ٱلۡكَوۡثَرَ فَصَلِّ لِرَبِّكَ وَٱنۡحَرۡ  إِنَّ شَانِئَكَ هُوَ ٱلۡأَبۡتَرُ ',
    transcription:
      'иннааа-аʿтаййнаакяль-кяус̱ар  фас̣алли-лираббикя-ванх̣ар инна-шяяни-акя-hуваль-абтар',
    quran:
      'Акыйкатта, Биз сага (о, Мухаммад) Каусарды тартуу кылдык.  Эми Роббиңе (сыйынып) намаз оку жана курмандык чал. Албетте, сени айыптаган адамдын өзүнүн арты кесилген!',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Каафирун Сүрөсү',
    arabic:
      ' قُلۡ یَـٰۤأَیُّهَا ٱلۡكَـٰفِرُونَ   لَاۤ أَعۡبُدُ مَا تَعۡبُدُونَ  وَلَاۤ أَنتُمۡ عَـٰبِدُونَ مَاۤ أَعۡبُدُ  وَلَاۤ أَنَا۠ عَابِد مَّا عَبَدتُّمۡ  وَلَاۤ أَنتُمۡ عَـٰبِدُونَ مَاۤ أَعۡبُدُ لَكُمۡ دِینُكُمۡ وَلِیَ دِینِ',
    transcription:
      'Къуль йаа аййюхаль кафирун Ляя а буду ма та будун ва ляя антум а бидуна ма а буду ва ляя ана а бидум ма а бадттум  ва ляя антум а бидуна маа а буду лякум дийнукум ва лия дийн',
    quran:
      'Айткын (О, Мухаммад): “Эй, каапырлар!  Мен силер ибадат кылган нерселерге ибадат кылбаймын! Силер дагы мен сыйынганга (Аллага) сыйынуучу эмессиңер. Мен силер ибадат кылган кудайларга сыйынбаймын! Силер дагы мен сыйынганга (Аллага) сыйынуучу эмессиңер. (Демек,) силердин диниңер – өзүңөр үчүн, ал эми мага өз диним!»',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Наср Сүрөсү',
    arabic:
      'إِذَا جَاۤءَ نَصۡرُ ٱللَّهِ وَٱلۡفَتۡحُ  وَرَأَیۡتَ ٱلنَّاسَ یَدۡخُلُونَ فِی دِینِ ٱللَّهِ أَفۡوَاجا  فَسَبِّحۡ بِحَمۡدِ رَبِّكَ وَٱسۡتَغۡفِرۡهُۚ إِنَّهُۥ كَانَ تَوَّابَۢا',
    transcription:
      'из̱яя-джааа-насруллааhи-валь-фатх̣ вара-аййтан-нааса-йадх̮улююна-фии-диинил-ляяhи-афуааджаа фасаббих̣-бих̣амди-раббикя-вастаг̣фирh иннаhуу-кяяна-тавваабаа',
    quran:
      'Алладан жардам жана жеңиш келген кезде  жана да Алланын динине топ-топ болуп, кирип жатканын көргөнүңдө  Ошондо Роббиңди мактоо айтуу менен даңктагын жана күнөөлөрүңдү кечирүүсүн сура! Чынында, Ал тооболорду кабыл кылуучу!',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Масад Сүрөсү',
    arabic:
      'تَبَّتۡ یَدَاۤ أَبِی لَهَب وَتَبَّ   مَاۤ أَغۡنَىٰ عَنۡهُ مَالُهُۥ وَمَا كَسَبَ سَیَصۡلَىٰ نَارا ذَاتَ لَهَب وَٱمۡرَأَتُهُۥ حَمَّالَةَ ٱلۡحَطَبِ فِی جِیدِهَا حَبۡل مِّن مَّسَدِۭ',
    transcription:
      'таббат-йадааа-абииляhабив-ватабб мааа-аг̣наа-ʿанhу-мааляhуу-вамаакясаб сайас̣лаа-нааранн-з̱аата-ляhаб вамра-атуhуу-х̣аммаа-ляталь-х̣атаб фии-джиидиhаа-х̣аблюм-мим-масад',
    quran:
      ' Абу Лахабдын эки колу куурап калсын! Ал куурап калды.   Ага мал-дүйносү да, кесиби да эч пайда берген жок. Ал жакында алоолонгон Тозокко ташталат. Ал эми (Пайгамбарга каршы доомат – ушак көтөрүп жүргөн) анын аялы болсо (наркы дүйнөдө) отун көтөргөн абалда!  Анын мойнунда (тозоктогу) курма дарагынын буласынан (эшилген) жип!',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Ихлас Сүрөсү',
    arabic:
      'قُلۡ هُوَ ٱللَّهُ أَحَدٌ  ٱللَّهُ ٱلصَّمَدُ لَمۡ یَلِدۡ وَلَمۡ یُولَدۡ وَلَمۡ یَكُن لَّهُۥ كُفُوًا أَحَدُۢ',
    transcription:
      'к̣уль-hувал-лааhу-ах̮ад аллааhус̣-с̣амад лям-йалид-ва-лям-йууляд ва-лям-йакул-ляhуу-куфуван-ах̣ад',
    quran:
      'Айткын (о, Мухаммад): Ал – Алла Жалгыз!  Алла эч кимге муктаж эмес! (Ал) төрөбөгөн жана төрөлбөгөн. Эч ким Ага тең эмес!',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Фалак Сүрөсү',
    arabic:
      'قُلۡ أَعُوذُ بِرَبِّ ٱلۡفَلَقِ  مِن شَرِّ مَا خَلَقَ  وَمِن شَرِّ غَاسِقٍ إِذَا وَقَبَ وَمِن شَرِّ ٱلنَّفَّـٰثَـٰتِ فِی ٱلۡعُقَدِ  وَمِن شَرِّ حَاسِدٍ إِذَا حَسَدَ ',
    transcription:
      'к̣уль-аʿууз̱у-бираббиль-фаляк̣ минн-шарри-маа-х̮аляк̣ ва-минн-шарри-г̣аасик̣ын-из̱яя-вак̣аб ва-минн-шаррин-наффаас̱аатифиль-ʿук̣ад ва-минн-шарри-х̣аасидин-из̱я-х̣асад',
    quran:
      'Aйткын (о, Мухаммад): «Таңдын Роббисинен коргоо тилеймин Ал жараткан (зыяндуу) нерселердин жамандыгынан Караңгылык каптаганда түндүн жамандыгынан Түйүндөргө дем салган сыйкырчылардын кастыгынан Сугун арткан учурда, көрөалбаган адамдардын жамандыгынан.»',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Нас Сүрөсү',
    arabic:
      'قُلۡ أَعُوذُ بِرَبِّ ٱلنَّاسِ   مَلِكِ ٱلنَّاسِ إِلَـٰهِ ٱلنَّاسِ  مِن شَرِّ ٱلۡوَسۡوَاسِ ٱلۡخَنَّاسِ ٱلَّذِی یُوَسۡوِسُ فِی صُدُورِ ٱلنَّاسِ مِنَ ٱلۡجِنَّةِ وَٱلنَّاسِ',
    transcription:
      ' к̣уль-аʿууз̱у-бираббин-нааас мяликин-нааас иляяhин-нааас минн-шарриль-васваасиль-х̮аннааас  алляз̱ии-йувасвису-фии-с̣удуурин-нааас миналь-джин-нати-ван-нааас',
    quran:
      ' Айткын (о, Мухаммад): “Адамдардын Роббисинен (коргоо тилеймин),  (Ал) адамдардын падышасы Ал (сыйынууга татыктуу болгон) адамдардын Кудайы. (Анын ысымы айтылганда) жашынып калуучу шайтандын жамандыгынан (коргоо тилеймин). Ал (шайтан) адамдардын көкүрөгүнө азгырык салат. Алар (азгыруучу шайтандар) жиндерден жана адамдардан болот.',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Ташаххуд Дубасы',
    arabic:
      'ٱلتَّحِيَّاتُ لِلَّٰهِ وَٱلصَّلَوَاتُ وَٱلطَّيِّبَاتُۘ ٱلسَّلَامُ عَلَيْكَ أَيُّهَا ٱلنَّبِيُّ وَرَحْمَةُ ٱللَّٰهِ وَبَرَكَاتُهُۘ ٱلسَّلَامُ عَلَيْنَا وَعَلَىٰ عِبَادِ ٱللَّٰهِ ٱلصَّالِحِينَۘ أَشْهَدُ أَنْ لَا إِلَٰهَ إِلَّا ٱللَّٰهُ وَأَشْهَدُ أَنَّ مُحَمَّدًا عَبْدُهُ وَرَسُولُهُ',
    transcription:
      'Ат-тахиййату ли Лляхи ва-с-салявату ва-т-таййибату ас-саляму алей-ка аййуха н-набиййу ва рахмату Ллахи ва баракяту-ху ас-саляму алей-на ва аля ибади Лляхи с-салихина Ашхаду ан ля иляха илля Ллаху ва ашхаду анна Мухаммадан абду-ху ва расулю-ху',
    quran: '',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Аят аль Курси',
    arabic:
      'اللَّهُ لَا إِلَٰهَ إِلَّا هُوَ الْحَيُّ الْقَيُّومُ ۚ لَا تَأْخُذُهُ سِنَةٌ وَلَا نَوْمٌ ۚ لَهُ مَا فِي السَّمَاوَاتِ وَمَا فِي الْأَرْضِ ۗ مَنْ ذَا الَّذِي يَشْفَعُ عِنْدَهُ إِلَّا بِإِذْنِهِ ۚ يَعْلَمُ مَا بَيْنَ أَيْدِيهِمْ وَمَا خَلْفَهُمْ ۖ وَلَا يُحِيطُونَ بِشَيْءٍ مِنْ عِلْمِهِ إِلَّا بِمَا شَاءَ ۚ وَسِعَ كُرْسِيُّهُ السَّمَاوَاتِ وَالْأَرْضَ ۖ وَلَا يَئُودُهُ حِفْظُهُمَا ۚ وَهُوَ الْعَلِيُّ الْعَظِيمُ',
    quran:
      'Аллах – андан башка сыйынууга татыктуу эч бир зат жок, Ал – түбөлүк Тирүү, Жашоо Берүүчү! Аны үргүлөө да, уйку да кармабайт. Асмандардагы жана жердегинин баары Аныкы. Анын алдында Өзүнүн уруксатысыз ким шапаат-колдоочулук кыла алат?! Ал алардын (макулуктардын)  келечегиндеги жана өтмүшүндөгү бардык нерселерди билет. Ал эми алар болсо Ал Өзү каалагандан башка эч нерсе билишпейт. Анын Курсийи асмандар жана Жерден да кенен. Ал экөөнү (асмандар менен жерди) сактап туруу Аны такыр чарчатпайт. Ал – эң Жогорку жана эң Улук!',
    transcription:
      'Аллооху лаа илааха иллаа хува-ль-Хаййу-ль-Коййуум. Лаа та’хузуху синатув ва лаа навм. Лаху маа фис-самааваати ва маа филь-ард. Ман зал-лазии йашфаъу ъиндаху иллаа би-изних. Йаъламу маа байна айдиихим ва маа хольфахум. Ва лаа йухиитууна би шай’им-мин ъильмихи иллаа би маа шаааа‘а. Васиъа курсиййуху-с-самааваати валь-ард. Ва лаа йа‘уудуху хифзухумаа. Ва хува-ль-Ъалиййу-ль-Ъазыым.',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Кунут Дубасы',
    arabic:
      'اللَّهُمَّ إِنَّا نَسْتَعِينُكَ وَنَسْتَغْفِرُكَ وَ نَسْتَهْدِكَ وَنُؤْمِنُ بِكَ وَ نَتُوبُ إِلَيْكَ وَنَتَوَكَّلُ عَلَيْكَ وَنُثْنِى عَلَيْكَ الْخَيْرَ كُلَّهُ وَنَشْكُرُكَ وَلاَ نَكْفُرُكَ وَنَخْلَعُ وَنَتْرُكُ مَنْ يَفْجُرُكَ اللَّهُمَّ إِيَّاكَ نَعْبُدُ وَلَكَ نُصَلِّى وَنَسْجُدُ وَإِلَيْكَ نَسْعَى وَنَحْفِدُ نَرْجُو رَحْمَتَكَ وَنَخْشَى عَذَابَكَ إِنَّ عَذَابَكَ بِالْكُفَّارِ مُلْحِقٌ',
    transcription:
      '«Аллаахумма, инна наста‘иинукя ва настагфирукь ва настахдиикя ва ну’мину бикя ва натуубу иляйк, ва натаваккялю аляйк, ва нуснии аляйкя-ль-хайра кулляхуу нашкурук, ва ляя накфурук ва нахля у ва натруку маййафджурук. Аллахумма, ийа-кя на буду, ва ля-кя нусолли ва насджуд, ва иляй-кя нас а ва нахфид, нарджу рахмата-кя, ва нахша азаба-кя, инна азаба-кял жидда би-ль-куффари мульхик!».',
    quran:
      'Оо, Аллахым! Озундон жардам сурайбыз, Озундон кечирим тилейбиз, Озундун ак жолундан адашпоону каалайбыз. Сага ишенебиз, алдында тообо кылабыз, Озуно таянабыз, бут жакшылыктар Озундон келгенине Сага мактоо-даназалар айтабыз. Сага шугурчулук келтиребиз, эч бир жакшылыгынды танбайбыз. Сенин бергендеринди танган жана Сага каршы болгондорду таштап, кол узобуз. Оо, Аллахым. Биз бир гана Озуно кулдук кылабыз. Озун учун намаз окуп, озуно сажда кылабыз. Озуно жакындатаар нерселерге гана умтулуп жан уройбуз. Ыкласыбыз менен Сага ибадат кылабыз. Мээриминден умуттонобуз да, азабындан коркуп турабыз, Сенин азабын ыймансыздарга берилээри шексиз',
  },
  {
    bismillah: '﷽',
    bismillahMeaning: 'Мээримдүү, Ырайымдуу Алланын аты менен!',
    name: 'Пайгамбарыбыз Мухаммед ﷺ саллаллаху алейхи вассаллам га Салават',
    arabic:
      'اللّهُـمَّ صَلِّ عَلـى مُحمَّـد، وَعَلـى آلِ مُحمَّد، كَمـا صَلَّيـتَ عَلـىإبْراهـيمَ وَعَلـى آلِ إبْراهـيم، إِنَّكَ حَمـيدٌ مَجـيد ، اللّهُـمَّ بارِكْ عَلـى مُحمَّـد، وَعَلـى آلِ مُحمَّـد، كَمـا بارِكْتَ عَلـىإبْراهـيمَ وَعَلـى آلِ إبْراهيم، إِنَّكَ حَمـيدٌ مَجـيد',
    transcription:
      'Аллахумма салли ‘аля Мухаммадин ва ‘аля али Мухаммадин, кя-ма салляйта ‘аля Ибрахима ва ‘аля али Ибрахима, инна-кя Хамидун, Маджидун. Ал-лахумма, барик ‘аля Мухаммадин ва ‘аля али Мухаммадин кя-ма баракта ‘аля Ибрахима ва ‘аля али Ибрахима, инна-кя Хамидун, Маджидун!',
    quran: '',
  },
];

export default surahs;
