import iteaasy from '../../../assetts/icons/iteeasy.png';
import arzanpods from '../../../assetts/icons/arzan.png';

const sponsors = [
  {
    name: 'ITeasy.KG',
    description: 'ITeasy.kg – Өспүрүмдөр үчүн 3 айлык программалоо курсу',
    image: iteaasy,
    link: 'https://iteasy-kg.netlify.app/',
    whatsapp: '+996556401369',
    telegram: '@iteasykg',
    instagram: 'iteasy.kg',
  },
  {
    name: 'Arzanstore.ru',
    description:
      'Arzanstore.ru – наушник телефондорду эн арзан баада таба алсыз.',
    image: arzanpods,
    link: 'https://arzanstore-ru.vercel.app/',
    whatsapp: '+79998152638',
    telegram: 'https://t.me/+cB86aKVNKShkMmYy',
    instagram: 'arzanpods.ru',
  },
  {
    name: 'ITeasy.KG',
    description: 'ITeasy.kg – Өспүрүмдөр үчүн 3 айлык программалоо курсу',
    image: iteaasy,
    link: 'https://iteasy-kg.netlify.app/',
    whatsapp: '+996556401369',
    telegram: '@iteasykg',
    instagram: 'iteasy.kg',
  },
];

export default sponsors;
