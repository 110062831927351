import img from '../../../assetts/icons/logo-nasykat.png';
import quran from './products-img/books-img/quran2.jpg';
import kurzhun from './products-img/namaz-img/tas.jpg';
import airpods1 from '../../../assetts/images/airpodsscreen.png';
import airpods2 from '../../../assetts/images/airpodsscreen2.png';

const products = [
  {
    id: 1,
    name: 'Сиздин товарыңыздын аты',
    price: 'сиздин баа',
    category: 'Эркектерге',
    description:
      'Lorem ipsum dolor sit amet consectetur,Сиздин товарыңыздын аты Lorem ipsum dolor sit amet consectetur,',
    images: [kurzhun, img],
    avatar: img,
    brandName: 'Сиздин магазин',
    verified: true,
    kg: '860 г', // Вес
    color: 'black', // Цвет
    material: 'Кожа', // Материал
    size: 'M', // Размер
    available: true, // Доступность
    links: {
      whatsapp: 'https://wa.me/0556401369',
      instagram: 'https://www.instagram.com/nasyikat.media',
      telegram: 'https://t.me/nasyikat.media',
      phone: 'tel:+996556401369',
    },
  },
  {
    id: 2,
    name: 'Сиздин товарыңыздын аты',
    description: 'товар жөнүндө кыскача маалымат',
    price: 'сиздин баа',
    images: [quran, img],
    avatar: img,
    brandName: 'Сиздин магазин',
    category: 'Военный',
    verified: false,
    links: {
      whatsapp: 'https://wa.me/0550565542',
      instagram: 'https://www.instagram.com/nasyikat.media',
      telegram: 'https://t.me/nasyikat.media',
      phone: 'tel:+996550565542',
    },
  },
  {
    id: 3,
    name: 'Smartwatch Men',
    description:
      'Фитнес-трекер для мужчин Беспроводные наушники airpods 2 Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quos, corporis, dolorum inventore molestiae cum maxime rem ullam earum labore, dicta expedita consectetur totam dolores corrupti minima reprehenderit quia iure adipisci.',
    price: 2000,
    images: [airpods1, airpods2, airpods1],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Кийимдер',
    links: {
      whatsapp: 'https://wa.me/996559333824',
      instagram: 'https://www.instagram.com/nasyikat.media',
      telegram: 'https://t.me/nasyikat.media',
      phone: 'tel:+996559333824',
    },
  },
  {
    id: 4,
    name: 'Running Shoes',
    description: 'Кроссовки для бега Беспроводные наушники airpods 2',
    price: 3000,
    images: [img, img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Үй-буюмдары',
    links: {
      whatsapp: 'https://wa.me/79998152638',
      instagram: 'https://www.instagram.com/nasyikat.media',
      telegram: 'https://t.me/nasyikat.media',
      phone: 'tel:+79998152638',
    },
  },
  {
    id: 5,
    name: 'Leather Sofa',
    description: 'Кожаный диван Беспроводные наушники airpods 2',
    price: 50000,
    images: [img, img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Спорт',
  },
  {
    id: 6,
    name: 'Digital Camera',
    description: 'Цифровая камера',
    price: 15000,
    images: [img, img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Аялдарга',
    links: {
      whatsapp: 'https://wa.me/996556401369',
      instagram: 'https://www.instagram.com/nasyikat.media',
      telegram: 'https://t.me/nasyikat.media',
      phone: 'tel:+996556401369',
    },
  },
  {
    id: 7,
    name: 'Winter Coat',
    description: 'Зимнее пальто',
    price: 7000,
    images: [img, img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Машина',
  },
  {
    id: 8,
    name: 'Portable Speaker',
    description: 'Портативная колонка',
    price: 2500,
    images: [img, img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Аксессуарлар',
  },
  {
    id: 9,
    name: 'Makeup Kit',
    description: 'Набор для макияжа',
    price: 1200,
    images: [img, img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Электроника',
  },
  {
    id: 10,
    name: 'Baby Stroller',
    description: 'Детская коляска',
    price: 8000,
    images: [img, img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Ремонт',
  },
  {
    id: 11,
    name: 'Car Seat Cover',
    description: 'Чехол для автокресла',
    price: 1500,
    images: [img, img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Косметика',
  },
  {
    id: 12,
    name: 'Electric Drill',
    description: 'Электродрель',
    price: 4000,
    images: [img, img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Бөбөктөргө',
  },
  {
    id: 13,
    name: 'Wristband',
    description: 'Браслет для фитнеса',
    price: 2000,
    images: [img, img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Эркектерге',
  },
  {
    id: 14,
    name: 'Smartphone',
    description: 'Смартфон с большими возможностями',
    price: 20000,
    images: [img, img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Военный',
  },
  {
    id: 15,
    name: 'Treadmill',
    description: 'Беговая дорожка',
    price: 40000,
    images: [img, img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Кийимдер',
  },
  {
    id: 16,
    name: 'Office Chair',
    description: 'Офисное кресло',
    price: 6000,
    images: [img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Үй-буюмдары',
  },
  {
    id: 17,
    name: 'Tablet',
    description: 'Планшет с высоким разрешением',
    price: 10000,
    images: [img, img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Машина',
  },
  {
    id: 18,
    name: 'Designer Dress',
    description: 'Платье от дизайнера',
    price: 15000,
    images: [img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Спорт',
  },
  {
    id: 19,
    name: 'Bike Helmet',
    description: 'Шлем для велосипеда',
    price: 3000,
    images: [img, img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Аялдарга',
  },
  {
    id: 20,
    name: 'Portable Charger',
    description: 'Портативное зарядное устройство',
    price: 800,
    images: [img],
    avatar: img,
    brandName: 'Nasyikat.shop',
    category: 'Аксессуарлар',
  },
];

export default products;
