import { styled } from '@mui/system';
import React from 'react';

const Welcome = () => {
  return <StyleContainer></StyleContainer>;
};

export default Welcome;

const StyleContainer = styled('div')({
  height: '70px',
});
