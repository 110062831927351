const arabicLetters = [
  { letter: 'ا', transliteration: 'Алиф' },
  { letter: 'ب', transliteration: 'Бa' },
  { letter: 'ت', transliteration: 'Ta' },
  { letter: 'ث', transliteration: 'Са' },
  { letter: 'ج', transliteration: 'Жим' },
  { letter: 'ح', transliteration: 'Ха' },
  { letter: 'خ', transliteration: 'Хо' },
  { letter: 'د', transliteration: 'Дал' },
  { letter: 'ذ', transliteration: 'Зал' },
  { letter: 'ر', transliteration: 'Ро' },
  { letter: 'ز', transliteration: 'За' },
  { letter: 'س', transliteration: 'Син' },
  { letter: 'ش', transliteration: 'Шин' },
  { letter: 'ص', transliteration: 'Сод' },
  { letter: 'ض', transliteration: 'Зод' },
  { letter: 'ط', transliteration: 'То' },
  { letter: 'ظ', transliteration: 'Зо' },
  { letter: 'ع', transliteration: 'Ъайн' },
  { letter: 'غ', transliteration: 'Гойн' },
  { letter: 'ف', transliteration: 'Фа' },
  { letter: 'ق', transliteration: 'Коф' },
  { letter: 'ك', transliteration: 'Каф' },
  { letter: 'ل', transliteration: 'Лам' },
  { letter: 'م', transliteration: 'Мим' },
  { letter: 'ن', transliteration: 'Нун' },
  { letter: 'و', transliteration: 'Вав' },
  { letter: 'ه', transliteration: 'Ха' },
  { letter: 'ي', transliteration: 'Йа' },
];

export default arabicLetters;
