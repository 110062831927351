import meccaImage from '../../../assetts/images/alaksa.jpg';
import Mecca from '../../../assetts/images/mecca.jpg';
import Madina from '../../../assetts/images/madina.jpg';

const muslim = [
  {
    title: 'Мекка',
    description:
      '«Чындыгында, адамзат үчүн курулган эң биринчи үй Баккадагы (Мекке). Ал үй бүткүл ааламдарге берекет жана туура жол үчүн курулган. Кимде-ким ага кирсе – тынчтыкта болот. Мүмкүнчүлүгү жеткендерге Аллах (ыраазычылыгы) үчүн Каабага ажылык кылуу милдети жүктѳлдү. Ким мындан жүз үйрүсѳ, чындыгында, Аллах – ааламдагыларга муктаж эмес!» («Аалу Имран» сүрѳѳсү, 96-97 - аяттар).',
    image: Mecca,
    link: 'https://ky.wikipedia.org/wiki/%D0%9C%D0%B5%D0%BA%D0%B5',
    location: 'Мекка, Сауд Арабиясы',
    date: '2024-07-14',
    time: '15:30',
  },
  {
    title: 'Медина',
    description:
      'Пайгамбарыбыз ﷺ айтты: " Кимде - ким Мадинада өлсө, ага менин шапаатым (колдоом) важиб болот »  деген. 10 миңден ашык куттуу сахабалардын денеси ошол жерге коюлган. Мадинага эч кандай жамандык келбейт, жамандар кирбейт. Мадинаи Мунаввара - жер жүзүндөгү эң пазилеттүү шаар, Пайгамбарыбыз ﷺ ал жерге хижрат кылган жери. Бул шаарда Курани Каримдин көптөгөн аяттары түшүрүлгөн, Пайгамбарыбыздын ﷺ сахабалары коюлган, Пайгамбарыбыздын ﷺ мубарак сөөгү коюлган. Мадина саламттыктын шаары, Мадина Аллах тааланын элчисинин шаары.',
    image: Madina,
    link: 'https://ky.wikipedia.org/wiki/%D0%9C%D0%B5%D0%B4%D0%B8%D0%BD%D0%B0',
    location: 'Медина, Сауд Арабиясы',
    date: '2024-07-15',
    time: '16:00',
  },
  {
    title: 'Аль-Акса',
    description:
      'Аллах Таала Аль-Куддуска мураскер болуу бактысын мусулмандарга ыроологон. Мусулмандар дээрлик 12 кылым бою (айрым кыска мезгилдерди кошпогондо) Иерусалимде бийлик жүргүзүшкөн. Куранда айтылат: “Ал силерди (ыйык) жерге орун басар кылды. Силердин берген нерседе силерди сыноо үчүн силердин кээ бириңерден үстөм кылып койду. Акыйкатта,Эгең жаза берүүдө өтө тез. Чындыгында, Ал Кечиримдүү жана Мээримдүү” (“Анам” сүрөөсү, 165-аят)',
    image: meccaImage,
    link: 'https://ru.wikipedia.org/wiki/%D0%90%D0%BB%D1%8C-%D0%90%D0%BA%D1%81%D0%B0',
    location: 'Иерусалим, Палестина',
    date: '2024-07-16',
    time: '17:00',
  },
];

export default muslim;
