import logo from '../../assetts/icons/logo-nasykat.png';

const courses = [
  {
    id: 1,
    title: 'Куран Курсу',
    description: 'Курс тууралуу кыскача маалымат',
    image: logo,
  },
  {
    id: 2,
    title: 'Сиздин курс',
    description: 'Курс тууралуу кыскача маалымат',
    image: logo,
  },
  {
    id: 3,
    title: 'Сиздин курс',
    description: 'Курс тууралуу кыскача маалымат',
    image: logo,
  },
  {
    id: 4,
    title: 'Сиздин курс',
    description: 'Курс тууралуу кыскача маалымат',
    image: logo,
  },
];

export default courses;
