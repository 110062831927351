import iteasy from "../../assetts/icons/iteeasy.png"
import matraimimg from "../../assetts/images/matraim.JPG"
import nurtimaximg from "../../assetts/images/nurti.JPG"
import musulmani571 from "../../assetts/icons/musulmani.jpg"
import ustazdannasaat from "../../assetts/images/11.jpg"

const followers = [
   {
      id: 1,
      name: "Matraim Nurmatov",
      country: "Владелец продукта",
      avatarUrl: matraimimg,
      instagram: "https://www.instagram.com/matraim.official/"
   },
   {
      id: 2,
      name: "ITeasy.Kg",
      country: "IT SCHOOL",
      avatarUrl: iteasy,
      instagram: "https://www.instagram.com/iteasy.kg/"
   },
   {
      id: 3,
      name: "Nurilek Maksatbek Uulu",
      country: "Руководитель продукта",
      avatarUrl: nurtimaximg,
      instagram: "https://www.instagram.com/matraim.official/"
   },

   {
      id: 4,
      name: "Musulmani.571",
      country: "Баяндар",
      avatarUrl: musulmani571,
      instagram: "https://www.instagram.com/musulmani.571/"
   },
   {
      id: 5,
      name: "Ustazdan.nasaat",
      country: "Баяндар",
      avatarUrl: ustazdannasaat,
      instagram: "https://www.instagram.com/ustazdan.nasaat/"
   }
]

export default followers
